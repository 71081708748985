<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {pharmacyService} from "@/services/pharmacy.service";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Pharmacies",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      editing: false,
      pharmacies: [],
      countries: [
        {
          id: 1,
          code: 'tg',
          name: 'Togo'
        },
        {
          id: 2,
          code: 'gh',
          name: 'Ghana'
        }
      ],
      pharmacy: {},
      title: "Pharmacies",
      items: [
        {
          text: "Accueil",
          href: "/"
        },
        {
          text: "Pharmacies",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "phone", label: "Téléphone", sortable: true },
        { key: "address", label: "Adresse", sortable: true },
        { key: "country", label: "Pays", sortable: true },
        { key: "actions", thStyle: { width: "15%" } },
        { key: "show_details", label: "Carte", thStyle: { width: "8%" } }
      ],
      submitted: false
    };
  },
  validations: {
    pharmacy: {
      name: { required },
      phone: { required },
      address: { required },
      latitude: { required },
      longitude: { required },
      country: { required },
    }
  },
  computed: {
    rows() {
      return this.pharmacies.length;
    }
  },
  mounted() {
    this.getPharmacies();
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    mapClicked(mouseArgs) {
      this.pharmacy.latitude = mouseArgs.latLng.lat();
      this.pharmacy.longitude = mouseArgs.latLng.lng();
    },
    async formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let result = this.pharmacy.id ? await pharmacyService.update(this.pharmacy.id, this.pharmacy) : await pharmacyService.create(this.pharmacy);
        if (result >= 400) {
          await Swal.fire("Erreur", "Une erreur s'est produite !", "warning");
        } else {
          await Swal.fire(this.pharmacy.id ? 'Modification' : 'Enregistrement', "Opération reussie !", "success")
          await this.getPharmacies();
          this.clearForm();
        }
      }
    },
    clearForm() {
      this.submitted = false;
      this.editing = false;
      this.pharmacy = {};
    },
    async getPharmacies() {
      this.pharmacies = await pharmacyService.get();
    },
    remove(id) {
      Swal.fire({
        title: "Suppression",
        text: `Vous êtes sûr de supprimer ${this.pharmacy.name} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non"
      }).then(async result => {
        if (result.value) {
          let result = await pharmacyService.remove(id);
          if (result.status === 200) await Swal.fire("Suppression", "Pharmacie supprimé avec succès !", "success");
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items">
      <button v-if="!editing" type="button" class="btn btn-success" @click="editing = true">Nouvelle pharmacie</button>
    </PageHeader>

    <div v-if="editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nouvelle pharmacie</h4>
            <p class="card-title-desc">Ajouter de nouvelle pharmacie à la base de donnée via le formulaire</p>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="name">Nom</label>
                        <input id="name" v-model="pharmacy.name" type="text" class="form-control"
                               :class="{ 'is-invalid': submitted && $v.pharmacy.name.$error }"/>
                        <div v-if="submitted && $v.pharmacy.name.$error" class="invalid-feedback">
                          <span v-if="!$v.pharmacy.name.required">Le nom de la pharmacie est obligatoire.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="phone">Téléphone</label>
                        <input id="phone" v-model="pharmacy.phone" type="text" class="form-control"
                               :class="{ 'is-invalid': submitted && $v.pharmacy.phone.$error }"/>
                        <div v-if="submitted && $v.pharmacy.phone.$error" class="invalid-feedback">
                          <span v-if="!$v.pharmacy.phone.required">Le numéro de téléphone est obligatoire.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="form-label" for="address">Adresse</label>
                        <input id="address" v-model="pharmacy.address" type="text" class="form-control"
                               :class="{ 'is-invalid': submitted && $v.pharmacy.address.$error }"/>
                        <div v-if="submitted && $v.pharmacy.address.$error" class="invalid-feedback">
                          <span v-if="!$v.pharmacy.address.required">L'adresse est obligatoire.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label class="form-label" for="country">Pays</label>
                        <select v-model="pharmacy.country" id="country" class="form-select"
                                :class="{ 'is-invalid': submitted && $v.pharmacy.country.$error }">
                          <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                        </select>
                        <div v-if="submitted && $v.pharmacy.country.$error" class="invalid-feedback">
                          <span v-if="!$v.pharmacy.country.required">La selection du pays est obligatoire.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label class="form-label" for="latitude">Latitude</label>
                        <input id="latitude" v-model="pharmacy.latitude" type="text" class="form-control"
                               :class="{ 'is-invalid': submitted && $v.pharmacy.latitude.$error }"/>
                        <div v-if="submitted && $v.pharmacy.latitude.$error" class="invalid-feedback">
                          <span v-if="!$v.pharmacy.latitude.required">Les coordonnées géographiques sont obligatoire.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3">
                        <label class="form-label" for="longitude">Longitude</label>
                        <input id="longitude" v-model="pharmacy.longitude" type="text" class="form-control"
                               :class="{ 'is-invalid': submitted && $v.pharmacy.longitude.$error }"/>
                        <div v-if="submitted && $v.pharmacy.longitude.$error" class="invalid-feedback">
                          <span v-if="!$v.pharmacy.longitude.required">Les coordonnées géographiques sont obligatoire.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <GmapMap :center="pharmacy ? { lat: parseFloat(pharmacy.latitude), lng: parseFloat(pharmacy.longitude) } : { lat: 0, lng: 0 }" :zoom="13" style="height: 100%"
                    @click="mapClicked">
                    <GmapMarker :position="pharmacy ? { lat: parseFloat(pharmacy.latitude), lng: parseFloat(pharmacy.longitude) } : { lat: 0, lng: 0 }" :clickable="true" :draggable="true"/>
                  </GmapMap>
                </div>
              </div>
              <button class="btn btn-primary me-3" type="submit">Enregistrer</button>
              <button class="btn btn-secondary" type="reset" @click.prevent="clearForm">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste des Pharmacies</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Affiche
                    <b-form-select class="form-control form-control-sm form-select form-select-sm ms-1 mx-1" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher..."
                        class="form-control form-control-sm ml-2 ms-1"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                  :items="pharmacies"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template #cell(actions)="row">
                  <b-button size="sm" class="bg-warning mx-2" @click="pharmacy = row.item; editing = true">
                    Modifier
                  </b-button>
                  <b-button size="sm" class="bg-danger" @click="remove(row.item.id)">
                    Supprimer
                  </b-button>
                </template>

                <template #cell(show_details)="row">
                  <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? 'Cacher' : 'Afficher'}}
                  </b-button>
                </template>

                <template #row-details="row">
                  <b-card class="mb-0">
                    <b-row class="mb-3">
                      <GmapMap :center="{ lat: parseFloat(row.item.latitude), lng: parseFloat(row.item.longitude) }" :zoom="14" style="height: 200px">
                        <GmapMarker :position="{ lat: parseFloat(row.item.latitude), lng: parseFloat(row.item.longitude) }"/>
                      </GmapMap>
                    </b-row>
                    <b-button size="sm" @click="row.toggleDetails">Cacher</b-button>
                  </b-card>
                </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

